import React from 'react';

import { Sidebar } from '../Sidebar';

export const Layout = ({ children }) => (
  <React.Fragment>
    <Sidebar />
    <main>{children}</main>
  </React.Fragment>
);
