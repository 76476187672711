import React from 'react';

import './sidebar.scss';

export const Sidebar = () => {
  return (
    <div className="sidenav">
      {/* <div className="logo-container"> */}
      {/* <Link to="/">
        <img src={logo} alt="" />
      </Link> */}
      {/* </div> */}
      <ul>
        <a href="/">
          <li
            className={
              typeof window !== 'undefined' && window.location.hash === ''
                ? 'active'
                : null
            }
          >
            Home
          </li>
        </a>
        <a href="/#about">
          <li
            className={
              typeof window !== 'undefined' && window.location.hash === '#about'
                ? 'active'
                : null
            }
          >
            About
          </li>
        </a>
        <a href="/#videos">
          <li
            className={
              typeof window !== 'undefined' &&
              window.location.hash === '#videos'
                ? 'active'
                : null
            }
          >
            Videos
          </li>
        </a>
        <a href="/#contact">
          <li
            className={
              typeof window !== 'undefined' &&
              window.location.hash === '#contact'
                ? 'active'
                : null
            }
          >
            Contact
          </li>
        </a>
      </ul>
    </div>
  );
};
